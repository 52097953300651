<template>
  <div
    class="text-image-banner"
    :class="[themeColorClass, imagePositionClass, haveTilesClass]"
  >
    <div v-if="bannerImage" class="text-image-banner__image-wrapper">
      <UiImg
        :image="bannerImage"
        class="text-image-banner__image"
        :sizes="{ xs: '512' }"
        use-image-size
      />
    </div>

    <div class="text-image-banner__content-wrapper">
      <UiLabel :label="label" />

      <UiHeader
        v-if="header"
        class="text-image-banner__title"
        :header="header"
        :header-layout="headerLayout"
        :header-position="headerPosition"
        :header-size="headerSize"
        :theme-color="themeColor"
      />

      <div v-if="subheader" class="text-image-banner__subtitle">
        {{ subheader }}
      </div>

      <T3HtmlParser
        v-if="bodytext"
        class="text-image-banner__text"
        :class="textPositionClass"
        :content="bodytext"
      />

      <ul v-if="tagList.length && !tagIcon" class="text-image-banner__tag-list">
        <li
          v-for="tag in tagList"
          :key="tag.id"
          class="text-image-banner__tag-list-item"
        >
          <SvgIconCheck class="text-image-banner__tag-list-item-marker" />

          {{ tag.name }}
        </li>
      </ul>

      <MacoButton
        :button="button"
        :class="textPositionClass"
        :lang="lang"
        class="text-image-banner__button"
      />
    </div>

    <div
      v-if="tagList.length && tagIcon"
      class="text-image-banner__product-icon-tiles"
    >
      <ProductIconTile
        v-for="tag in tagList"
        :key="tag.id"
        :item="tag"
        show-link-icon
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiCeCaseStudyBannerProps } from '~/types'
import SvgIconCheck from '~ui/assets/icons/icon-check.svg'
import { MacoButton, UiHeader, UiImg, UiLabel } from '~ui/components'
import ProductIconTile from '~ui/components/T3Ce/T3CeProductBoxes/ProductIconTiles/ProductIconTile.vue'
import useTheming from '~ui/composables/useTheming'

const props = defineProps<UiCeCaseStudyBannerProps>()

const themeColorClass = useTheming(props.themeColor)
const textPositionClass = computed(() => `text-${props.headerPosition}`)

const bannerImage = computed(() => props.image?.[0] || '')

const imagePositionClass = computed(() => {
  return `text-image-banner--image-${props.imagePosition}`
})

const haveTilesClass = computed(() => {
  return props.tagList.length && props.tagIcon
    ? 'text-image-banner--tiles'
    : null
})
</script>

<style lang="scss">
.text-image-banner {
  $base: &;

  display: grid;
  justify-content: space-between;
  align-items: center;
  gap: rem(48px);
  grid-template-columns: 1fr;
  grid-template-areas:
    'image'
    'content';

  &#{$base}--tiles {
    grid-template-areas:
      'image'
      'content'
      'tiles';
  }

  @include media-query(md) {
    gap: rem(16px) rem(64px);
    grid-template-columns: 0.48fr 0.52fr;
    grid-template-areas: 'image content';

    &#{$base}--tiles {
      grid-template-areas:
        'image content'
        'image tiles';
    }

    &#{$base}--image-right {
      grid-template-columns: 0.52fr 0.48fr;
      grid-template-areas: 'content image';

      &#{$base}--tiles {
        grid-template-areas:
          'content image'
          'tiles image';
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    &-wrapper {
      grid-area: image;
      @include media-query(max-width md) {
        justify-content: center;
      }

      @include media-query(md) {
        #{$base}--image-right & {
          justify-content: flex-end;
        }
      }
    }
  }

  &__content-wrapper {
    grid-area: content;
  }

  &__image-wrapper + &__content-wrapper {
    @include media-query(max-width sm) {
      margin-top: rem(30px);
    }
  }

  & .ui-label {
    margin-bottom: rem(30px);
  }

  &__title > * {
    margin: 0;
  }

  &__subtitle {
    color: color(gray);
    font-size: rem(20px);
    font-weight: 600;
    padding-top: em(30px);
  }

  &__text {
    color: color(gray);
    font-size: rem(18px);
    margin-top: rem(30px);

    @include themed-link;
  }

  &__tag-list {
    display: flex;
    flex-direction: column;
    gap: rem(8px);
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      display: flex;
      align-items: center;
      gap: rem(8px);
      font-size: rem(18px);
      font-weight: bold;
      line-height: 1.2;

      &-marker {
        flex-shrink: 0;
        width: 32px;
        height: 32px;

        & path {
          fill: var(--theme__theme-color);
        }
      }

      & + & {
        margin-top: -2px;
      }
    }
  }

  &__product-icon-tiles {
    grid-area: tiles;
    display: grid;
    gap: 1rem;

    @include media-query(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__button {
    margin: 0 0 rem(40px);

    &.text-left > * {
      margin-left: 0;
    }

    @include media-query(max-width sm) {
      margin: rem(15px) 0 0;
    }
  }
}
</style>
